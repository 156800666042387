import './Loading.css'

export default function Loading() {
    return <div className='load_bg'>
        <div className="load6">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
        </div>
    </div>
}
import successIcon from './assets/svg/success2.svg'
import errorIcon from './assets/svg/error.svg'
import './Result.css'
import { useLocation } from 'react-router-dom';

export default function Result() {
    const location = useLocation();
    const state = location.state || {}; // 获取传递的参数
    return <div className='container'>
        {
            state.retCode == '0000' ? <img src={successIcon} /> : <img src={errorIcon} />
        }

        <span className='result' style={{ color: state.retCode == '0000' ? '#59CCB2' : 'red' }}>{state.retCode == '0000' ? '兑换成功' : '兑换失败'}</span>
        {
            state.retCode == '0000' ? <span className='desc' style={{ whiteSpace: 'pre-line' }}>立减金已存入您的微信账户中{"\n"}请在自贩机选购商品{"\n"}使用微信支付，自动抵扣</span> :
                <span className='desc' style={{ whiteSpace: 'pre-line' }}>{state.retMsg}</span>
        }
        <img src={require('./assets/result_tip.png')} style={{marginTop:'0.3rem',width:'2.58rem',height:'1.81rem'}}/>

        <img src={require('./assets/result_ad.png')} style={{marginTop:'0.6rem',width:'3.2rem',height:'0.74rem'}}/>

    </div>
}

import React, { useEffect, useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import successIcon from './assets/svg/success.svg'
import netIcon from './assets/svg/wifi.svg'
import warnIcon from './assets/svg/warn.svg'

export default function Toast({ message, duration = 3000, type = 'warn', onClose, isVisible }) {
    // const [isShow,setIsShow] = useState(isVisible)
    // useEffect(() => {
    //     console.log('isVisible',isVisible,duration)
    //     if (isVisible ) {
    //         const timer = setTimeout(() => {
    //             console.log('close',isVisible,duration)
    //             onClose()
    //         }, duration)

    //         return () => clearTimeout(timer)
    //     }
    // }, [isVisible, duration, onClose])

    useEffect(()=>{
        if (isVisible ) {
            const timer = setTimeout(() => {
                console.log('close',isVisible,duration)
                onClose()
            }, duration)

            return () => clearTimeout(timer)
        }
    },[])

    return (
        <AnimatePresence>
            {isVisible && (
                <motion.div
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 50 }}
                    transition={{ duration: 0.3 }}
                    style={{
                        position: 'fixed',
                        top: '40%',
                        left: '0.65rem',
                        right: '0.65rem',
                        transform: 'translate(-50%, -50%)',
                        backgroundColor: 'white',
                        borderRadius: '8px',
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                        padding: '16px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        zIndex: 500,
                    }}
                // className="fixed bottom-4 left-1/2 transform -translate-x-1/2 bg-white rounded-lg shadow-lg p-4 flex flex-col items-center"
                >
                    <div style={{height:'0.15rem'}}/>
                    {type == 'warn' && <img src={warnIcon} />}
                    {type == 'success' && <img src={successIcon} />}
                    {type == 'net' && <img src={netIcon} />}
                    <p className="text-gray-800 font-medium" style={{ fontSize: '0.16rem', textAlign: 'center',marginTop:'0.16rem' }}>{message}</p>
                </motion.div>
            )}
        </AnimatePresence>
    )
}
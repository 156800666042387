// src/api.js
import axios from 'axios';

// 创建一个 Axios 实例
const api = axios.create({
    baseURL: 'https://sp.sanhangzhongye.com', // 设置基础 URL
    timeout: 100000, // 设置请求超时时间
});

// 请求拦截器
api.interceptors.request.use(
    (config) => {
        // 在请求发送之前做一些处理，例如添加 token
        const token = localStorage.getItem('token'); // 假设 token 存储在 localStorage
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// 响应拦截器
api.interceptors.response.use(
    (response) => {
        // 处理响应数据
        return response.data; // 直接返回数据部分
    },
    (error) => {
        return Promise.reject(error);
    }
);

export const API_QUERY_BALANCE = '/api/cy/queryCmccBalance'
export const API_BIND_PHONE = '/api/cy/bindingOrActCmcc'
export const API_SEND_BIND_CODE = '/api/cy/sendCmccSms'
export const API_PRODUCTS = '/api/cy/queryGoodsList'

export const API_ORDER = '/api/cy/placeOrder'
export const API_EXCHANGE = '/api/cy/dectOrder'
export const API_PAY_CODE = '/api/cy/sendPaySms'


// 封装 GET 请求
export const get = (url, params) => {
    return api.get(url, { params });
};

// 封装 POST 请求
export const post = (url, data) => {
    return api.post(url, data);
};

// 封装 PUT 请求
export const put = (url, data) => {
    return api.put(url, data);
};

// 封装 DELETE 请求
export const del = (url) => {
    return api.delete(url);
};

export default api;
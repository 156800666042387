import { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import './main.css'
import { API_BIND_PHONE, API_EXCHANGE, API_ORDER, API_PAY_CODE, API_PRODUCTS, API_QUERY_BALANCE, API_SEND_BIND_CODE, post } from './service/api';
import Toast from "./Toast";
import Loading from './Loading';
import errorIcon from './assets/svg/error.svg'


let dayta = {
    token: ''
}
export default function Main() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const navigate = useNavigate();
    const [phoneNumber, setPhoneNumber] = useState(queryParams.get('phone') ?? '')
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [verificationCode, setVerificationCode] = useState('')
    const [countdown, setCountdown] = useState(60)
    const [isCountdownActive, setIsCountdownActive] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    const [alertContent, setAlertContent] = useState('测试')


    const [code, setCode] = useState('')
    const [countdown2, setCountdown2] = useState(60)
    const [isCountdown, setIsCountdown] = useState(false)


    const [hasLogin, setHasLogin] = useState(false)
    const [jsLoaded, setJsLoaded] = useState(false)
    const [checked, setChecked] = useState(true)
    const [points, setPoints] = useState(0)
    const [toast, setToast] = useState({
        show: false,
        msg: ''
    })

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [product, setProduct] = useState(null)
    const [loading, setLoading] = useState(false)
    const [orderId, setOrderId] = useState('')



    const kChannelSource = '02013604'

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const confirmAction = () => {
        console.log('confirm')
        // 确认的操作
        setModalIsOpen(false);
        setChecked(true)

        handleSendCode(true)

    };


    const textArray = [
        '本活动仅限中国移动手机号码客户参与。  ',
        '活动方有权对违规行为（如虚假交易、恶意套现等）进行撤销并收回立减金，必要时追究相关行为的法律责任。',
    ];

    const [inputValue, setInputValue] = useState('');
    const [formattedValue, setFormattedValue] = useState('');

    useEffect(() => {
        loadJs()
    }, [])

    useEffect(() => {
        if (!modalIsOpen) {
            setVerificationCode('')
        }
    }, [modalIsOpen])

    useEffect(() => {
        if (jsLoaded) {
            if (phoneNumber.length > 0) {
                handleSendCode()
            }
        }
    }, [jsLoaded])

    function checkBalance() {
        setLoading(true)
        var phone = phoneNumber//'19525615495'//phoneNumber
        post(API_QUERY_BALANCE, {
            mobile: phone,
            channelSource: kChannelSource,
            sessionid: window.sessionId,
            fingerprint: window._fmOpt.getinfo(),
            callbackUrl: 'https://m.baidu.com'
        }).then(res => {
            if (res.data.retVal == '888') {
                console.log('发送验证码')
                dayta.token = res.data.validateToken
                setIsModalOpen(true)
                setIsCountdownActive(true)
                setCountdown(60)
                setLoading(false)
            }
            else if (res.data.points || res.data.points == 0) {
                setPoints(res.data.points)
                setLoading(false)
                products(phone)
            }
            else {
                setLoading(false)
                setToast({
                    msg: res.data.msg,
                    show: true
                })
            }
        })
    }


    function isChinaMobile(phone) {
        const regex = /^(13[4-9]|147|15[0-2,7-9]|178|18[2-4,7-8]|194|195|197|198)\d{8}$/;
        return regex.test(phone);
    }

    function bindPhone() {
        const regex = /^\d{6}$/;
        if (!regex.test(verificationCode)) {
            setToast({
                msg: '请输入6位数字短信验证码',
                show: true
            })
            return;
        }

        setLoading(true)
        post(API_BIND_PHONE, {
            mobile: phoneNumber,
            validateToken: dayta.token,
            smsCode: verificationCode
        }).then(res => {
            console.log('bind', res)
            if (res.data.retVal == '888') {
                checkBalance()
            }
            if (res.data.retVal == '802') {
                setLoading(false)
                setToast({
                    msg: '短信验证码错误，请重试',
                    show: true
                })
            }
            else {
                setLoading(false)
                setToast({
                    msg: res.data.msg,
                    show: true
                })
            }
            // sendCode(token, phone)
        })
    }

    function sendCode(token) {
        setLoading(true)
        post(API_SEND_BIND_CODE, {
            mobile: phoneNumber,
            validateToken: token,
        }).then(res => {
            console.log('sendCode', res)
            setLoading(false)
            if (res.data.retVal == '888') {
            }
            else {
                setToast({
                    msg: res.data.msg,
                    show: true
                })
            }
            // products(phone)
        })
    }

    function products(phone) {
        setLoading(true)
        post(API_PRODUCTS, {
            mobile: phone,
            channelSource: kChannelSource
        }).then(res => {
            console.log('products', res)
            setLoading(false)
            if (res.data && Array.isArray(res.data)) {
                setProduct(res.data[0])

                createOrder(res.data[0])
            }
            else {
                if (res.retCode == 'F001') {
                    setToast({
                        msg: '您的积分不够\n暂无可兑换的商品',
                        show: true
                    })
                }
                else {

                    setToast({
                        msg: res.retMsg,
                        show: true
                    })
                }
            }

        })
    }

    function createOrder(data = product) {
        setLoading(true)
        post(API_ORDER, {
            mobile: phoneNumber,
            channelSource: kChannelSource,
            goodsNo: data.goodsNo
        }).then(res => {
            setLoading(false)
            setIsModalOpen(false)
            if (res.retCode == '0000') {
                setHasLogin(true)
                setOrderId(res.data.orderId)
                setIsCountdown(true)
                setCountdown2(60)
            }
            else {
                setAlertContent(res.retMsg)
                setShowAlert(true)

            }


        })
    }

    function pay() {
        const regex = /^\d{6}$/;
        if (!regex.test(code)) {
            setToast({
                msg: '请输入6位数字短信验证码',
                show: true
            })
            return;
        }
        setLoading(true)
        post(API_EXCHANGE, {
            orderId,
            smsCode: code
        }).then(res => {
            setLoading(false)
            navigate('result', { state: res })
        })
    }

    function loadJs() {
        var url, ts, sessionId, appId, appkey, cdntype = '';
        url = "tfd.changyoyo.com";
        appId = "5308e20b";
        appkey = "3daf27ea2fe840ada6af8e1ec0d7b760";
        ts = new Date().getTime();
        sessionId = "changyo-pc-" + ts + "-" + Math.random().toString(16).substr(2);
        window.sessionId = sessionId;

        var script = document.createElement('script')
        script.src = "//" + url + "/did/js/dp.js?appId=" + appId + "&appkey=" + appkey + "&sessionId=" + sessionId + "&ts=" + ts

        var firstScript = document.getElementsByTagName('script')[0];
        firstScript.parentNode.appendChild(script)

        // script.onload = () => {
        //     console.log('aaaaaaa')
        //     console.log(window.sessionId)
        // }

        var protocol, isProd, doc = document;
        protocol = ('https:' == doc.location.protocol ?
            'https://' : 'http://');
        window._fmOpt = {
            display: 'bind', //popup方式渲染验证码
            container: '.captcha', //验证码button渲染的目标DOM，自动展现方式下必需，详情见后文
            area: '.customForm',//对于custom模式，弹出窗口的加载容器，详情见后文
            partner: "changyou",
            appName: "changyou_web",
            fmb: true,
            hideCompInfo: true,
            initialTime: new Date().getTime(),
            token: "changyou" + "-" + new Date().getTime()
                + "-" + Math.random().toString(16).substr(2),
            env: 1,//测试0，产线1
            getinfo: function () {
                return "e3Y6ICIyLjUuMCIsIG9zOiAid2ViIiwgczogMTk5LCBlOiAianMgbm90IGRvd25sb2FkIn0=";
            },
        };
        var fm = doc.createElement('script');
        fm.type = 'text/javascript';
        fm.src = ('https:' == doc.location.protocol ?
            'https://' : 'http://') +
            'static.tongdun.net/captcha/main/tdc.js?ver=1.0&t=' +
            (new Date().getTime() / 600000).toFixed(0);
        var s = doc.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(fm, s);

        Promise.all([script.onload, fm.onload]).then(res => {
            setJsLoaded(true)
        })

        // fm.onload = () => {
        //     console.log('bbbbbbb')
        //     console.log(window._fmOpt.getinfo())
        // }
    }


    useEffect(() => {
        let timer;
        if (isCountdownActive && countdown > 0) {
            timer = setTimeout(() => {
                setCountdown(countdown - 1)
            }, 1000)
        } else if (countdown === 0) {
            setIsCountdownActive(false)
        }
        return () => clearTimeout(timer)
    }, [countdown, isCountdownActive])

    useEffect(() => {
        let timer2;
        if (isCountdown && countdown2 > 0) {
            timer2 = setTimeout(() => setCountdown2(countdown2 - 1), 1000)
        } else if (countdown2 === 0) {
            setIsCountdown(false)
        }
        return () => clearTimeout(timer2)
    }, [countdown2, isCountdown])

    useEffect(() => {
        if (isModalOpen || modalIsOpen) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'unset'
        }

        return () => {
            document.body.style.overflow = 'unset'
        }
    }, [isModalOpen, modalIsOpen])

    const handleSendCode = (select = checked) => {
        if (phoneNumber.length === 11) { // 简单的手机号验证
            if (!select) {
                setModalIsOpen(true)
                return;
            }
            if (!isChinaMobile(phoneNumber)) {
                setShowAlert(true)
                setAlertContent('活动仅限移动用户，请输入移动手机号')

                // setIsModalOpen(true)
                // setIsCountdownActive(true)
                // setCountdown(60)
                return
            }
            checkBalance()
        } else {
            setToast({
                msg: '请输入正确的手机号',
                show: true
            })
        }
    }

    const handleResendCode = () => {
        post(API_SEND_BIND_CODE, {
            mobile: phoneNumber,
            validateToken: dayta.token,
        }).then(res => {
            console.log('sendCode', res)
            dayta.token = res.data.validateToken
            setIsCountdownActive(true)
            setCountdown(60)
            // products(phone)
        })

        // 这里应该有重新发送验证码的逻辑
    }

    const resendCode = () => {
        post(API_PAY_CODE, {
            orderId: orderId
        }).then(res => {
            if (res.retCode == '0000') {
                setIsCountdown(true)
                setCountdown2(60)
            }
            else {
                setToast({
                    msg: res.retMsg,
                    show: true
                })
            }

            // products(phone)
        })
    }

    const handleBlur = () => {
        // 清除空格并检查输入的长度
        const cleanedValue = inputValue.replace(/\s+/g, '');

        if (cleanedValue.length === 11 && /^\d+$/.test(cleanedValue)) {
            const groupedValue = `${cleanedValue.slice(0, 3)} ${cleanedValue.slice(3, 7)} ${cleanedValue.slice(7)}`;
            setFormattedValue(groupedValue);
        } else {
            setFormattedValue('');
        }
    };

    function unlogin() {
        return <div>
            <div className='card'>
                <div className='title1'>恭喜获得</div>
                <div className='coupon_bg'>
                    <img src={require('./assets/dash_line.png')} className='dash_line' />
                    <div className='coin'></div>
                    <div className='coupon_left'>
                        <div className='tag'>￥</div>
                        <div className='coupon_price'>8</div>
                    </div>
                    <div className='coupon_right'>
                        <span className='coupon_name' style={{ whiteSpace: 'pre-line' }}>中粮自贩机{'\n'}代  金  券</span>
                        {/* <span className='coupon_name'>代金券</span> */}
                    </div>

                </div>
                <input className='input'
                    style={{ marginTop: '1rem' }}
                    placeholder='请输入您的手机号'
                    type="tel"
                    value={phoneNumber}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            handleSendCode()
                        }
                    }} // 监听键盘事件
                    onChange={(e) => setPhoneNumber(e.target.value)}
                />
                <div className='exchange' onClick={() => {
                    handleSendCode()
                }}>立即兑换</div>
                <div className='agree_bg'>
                    <img onClick={() => {
                        setChecked(!checked)
                    }} src={checked ? require('./assets/checked.png') : require('./assets/check.png')} className='check_icon' />
                    <span className='check_desc'>我已阅读并同意<span style={{ color: '#F4D448' }} onClick={() => {
                        navigate('protocol')
                        // window.location.href = 'protocol?type=privacy'
                    }}>《隐私政策》</span></span>
                </div>
            </div>
            <div className='rule_card'>
                <div className='rule_header'>
                    <div className='rule_text'>兑换规则</div>
                </div>
                <div className='rule_content'>
                    {textArray.map((text, index) => (
                        <div className="text-item" key={index}>
                            <div className="red-square"></div>
                            <span>{text}</span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    }

    function productContent() {
        return <div>
            <div className='balance'>
                <div className='current_phone'>{phoneNumber.substring(0, 3)} **** {phoneNumber.substring(phoneNumber.length - 4, phoneNumber.length)}</div>
                <div className='score'>移动积分：{points}</div>
            </div>
            <div className='rule_card' style={{ marginBottom: 0 }}>
                <div className='exchange_header'>
                    <div className='rule_text' style={{ marginTop: '0.1rem' }}>您本次可兑换</div>
                </div>
                {/* <img src={successIcon} /> */}
                <div className='rule_content' style={{ alignItems: 'center' }}>
                    <div className='coupon_bg' style={{ marginTop: '0.02rem' }}>
                        <img src={require('./assets/dash_line.png')} className='dash_line' />
                        <div className='coupon_left'>
                            <div className='tag'>￥</div>
                            <div className='coupon_price'>{product.goodsFaceValue}</div>
                        </div>
                        <div className='coupon_right'>
                            <span className='coupon_name'>{product.goodsName}</span>
                        </div>

                    </div>
                    <div className='condition'>本次兑换需消耗移动积分：{product.points}</div>
                    <div style={{
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        height: '0.5rem',
                        overflow: 'hidden'
                    }}>
                        <input className='input'
                            style={{ paddingRight: '1rem', }}
                            placeholder='请输入验证码'
                            maxLength={6}
                            type="number"
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                        />
                        <button
                            className='code'
                            onClick={resendCode}
                            disabled={isCountdown}
                        >
                            {isCountdown ? `重新发送(${countdown2}s)` : '重新发送'}
                        </button>
                    </div>
                    <div className='exchange' onClick={pay}>确认兑换</div>
                </div>
            </div>
            <div className='detail_title'>产品详情</div>
            <div className='detail_intro'>{product.goodsShow}</div>
            <div className='detail_title'>使用规则</div>
            <div className='detail_intro' style={{ whiteSpace: 'pre-line' }}>1.立减金仅限使用微信支付时1:1抵扣现金，例如：消费满2.01元使用立减金抵扣2元，实际支付为0.01元。{'\n'}
                2.微信支付时默认优先使用最大能抵扣的立减金面额自动抵扣，每次限用一张。{'\n'}
                3.微信立减金7日内有效，过期作废不退不换，请及时使用。</div>
        </div>
    }

    return <div className='container'>
        <img className='cover' src={require('./assets/header.png')} />
        {
            !hasLogin && unlogin()
        }
        {
            hasLogin && productContent()
        }

        {isModalOpen && (
            <div className="modal">
                <div className="modal-content">
                    <div style={{
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        height: '0.5rem',
                        overflow: 'hidden'
                    }}>
                        <input className='input'
                            style={{ paddingRight: '1rem', }}
                            placeholder='请输入验证码'
                            maxLength={6}
                            type="number"
                            value={verificationCode}
                            onChange={(e) => setVerificationCode(e.target.value)}
                        />
                        <button
                            className='code'
                            onClick={handleResendCode}
                            disabled={isCountdownActive}
                        >
                            {isCountdownActive ? `重新发送(${countdown}s)` : '重新发送'}
                        </button>
                    </div>

                    <div className='exchange' onClick={bindPhone}>确认兑换</div>
                </div>
                <img src={require('./assets/close.png')} className='close_icon' onClick={() => {
                    setIsModalOpen(false)
                }} />
            </div>
        )}

        {
            showAlert && <div className="modal">
                <div className="modal-content">
                    <img src={errorIcon} />
                    <span className='alert_title'>验证失败</span>
                    <span className='alert_content'>{alertContent}</span>
                    <img src={require('./assets/banner.png')} style={{ marginTop: '0.2rem', width: '3rem', height: '0.7rem' }} />
                </div>
                <img src={require('./assets/close.png')} className='close_icon' onClick={() => {
                    setShowAlert(false)
                }} />
            </div>
        }

        {
            modalIsOpen && <div
                className="modal"
            >

                <div className='modal_content'>
                    <span className='modal_title'>请已阅读并同意<span style={{ color: '#FF6B00' }}
                        onClick={() => {
                            navigate('protocol')
                            // window.location.href = 'protocol?type=privacy'
                        }}
                    >《隐私政策》</span>
                        {/* <span style={{ color: '#FF6B00' }}
                        onClick={() => {
                            navigate('protocol')
                        }}
                    >《用户协议》</span> */}
                    </span>
                    <div className="ButtonContainer">
                        <div onClick={closeModal} className='cancel'>取消</div>
                        <div onClick={confirmAction} className='confirm'>确认</div>
                    </div>
                </div>

            </div>
        }
        {
            toast.show && <Toast
                message={toast.msg}
                isVisible={toast.show}
                onClose={() => {
                    setToast({
                        msg: '',
                        show: false
                    })
                }}
                duration={3000}
            />
        }


        {
            loading && <Loading />
        }



    </div>
}
import logo from './logo.svg';
import './App.css';
import Main from './main';
import { useEffect, useState } from 'react';
import Result from './Result';
import Procotol from './Protocol';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

function App() {
  const [path, setPath] = useState(window.location.pathname);

  const navigate = (newPath) => {
    setPath(newPath);
    window.history.pushState(null, '', newPath); // 更新浏览器历史记录
  };

  // 根据路径渲染不同的组件
  const renderContent = () => {
    switch (path) {
      case '/about':
        return <Result />;
      case '/protocol':
        return <Procotol />
      case '/':
      default:
        return <Main />;
    }
  };
  useEffect(() => {
    console.log('index page')
  }, [])

  return (
    // <div>
    //   {renderContent()}
    // </div>
    <Router>
      {/* <nav>
                <Link to="/">Home</Link>
                <Link to="/protocol">Protocol</Link>
                <Link to="/result">Result</Link>
            </nav> */}
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/protocol" element={<Procotol />} />
        <Route path="/result" element={<Result />} />
      </Routes>
    </Router>
  );
}

export default App;
